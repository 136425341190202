import { writable, readable, derived } from 'svelte/store';
import { selectedDatensatz } from '$lib/stores/storesDashboard';

export let disclaimerModalVisible = writable(false);
export let queryString = writable('');

export const datensaetze = writable({
	incora: {
		label: 'Incora Erdbeobachtung',
		subDatensaetze: {
			'incora-bodenbedeckung': {
				label: 'Incora Bodenbedeckung',
				headingChartClasses: 'Verteilung der Bodenbedeckung nach Klassen',
				headingChartChange: 'Veränderung der Bodenbedeckung nach Klassen'
			},
			'incora-bodenversiegelung': {
				label: 'Incora Bodenversiegelung',
				headingChartClasses: 'Verteilung nach Grad der Bodenversiegelung',
				headingChartChange: 'Veränderung der Bodenversiegelung'
			}
		}
	},
	europe: {
		label: 'Europäische Erdbeobachtung',
		subDatensaetze: {
			'europe-clc': {
				label: 'Copernicus Bodenbedeckung',
				headingChartClasses: 'Verteilung der Bodenbedeckung nach Klassen',
				headingChartChange: 'Veränderung der Bodenbedeckung nach Klassen'
			},
			'europe-ci': {
				label: 'Copernicus Bodenversiegelung',
				headingChartClasses: 'Verteilung nach Grad der Bodenversiegelung',
				headingChartChange: 'Veränderung der Bodenversiegelung'
			}
		}
	},
	destatis: {
		label: 'Statistische Landesämter',
		subDatensaetze: {
			'destatis-regional': {
				label: 'Regionalstatistik',
				headingChartClasses: 'Verteilung der Landnutzung nach Klassen',
				headingChartChange: 'Veränderung der Landnutzung nach Klassen'
			}
		}
	},
	geobasisdaten: {
		label: 'Amtliche Geobasisdaten',
		subDatensaetze: {
			'geobasisdaten-alkis': {
				label: 'Kataster-Informationssystem (ALKIS)',
				headingChartClasses: 'Verteilung der Landnutzung nach Klassen',
				headingChartChange: 'Veränderung der Landnutzung nach Klassen'
			},
			'geobasisdaten-atkis': {
				label: 'Digitales Landschaftsmodell (ATKIS)',
				headingChartClasses: 'Verteilung der Landnutzung nach Klassen',
				headingChartChange: 'Veränderung der Landnutzung nach Klassen'
			}
		}
	}
});

export const mobileSize = readable(1024);
export let isMobile = writable(true);

export const selectedDatensatzChartHeadings = derived(
	[selectedDatensatz, datensaetze],
	([$selectedDatensatz, $datensaetze]) => {
		let headings = {
			classes: '',
			change: ''
		};
		headings.classes = Object.values($datensaetze).filter((item) => {
			return item.subDatensaetze[$selectedDatensatz];
		})[0].subDatensaetze[$selectedDatensatz].headingChartClasses;

		headings.change = Object.values($datensaetze).filter((item) => {
			return item.subDatensaetze[$selectedDatensatz];
		})[0].subDatensaetze[$selectedDatensatz].headingChartChange;

		return headings;
	}
);

// export const selectedAdminName = derived(
// 	[selectedAdminId, lookupTable],
// 	([$selectedAdminId, $lookupTable]) => {
// 		const id = $selectedAdminId || '-1';
// 		return $lookupTable[id].name_gebietseinheit;
// 	}
// );
