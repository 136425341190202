import { writable, readable, derived } from 'svelte/store';

import lookupTabelData from '../../../static/data/lookupTabel.json';
export const lookupTable = readable(lookupTabelData);

export let selectedDatensatz = writable('incora-bodenbedeckung');

// Map settings
export let mapCenter = writable([10.426, 50.741]);
export let mapZoom = writable(4.9);
// export let mapColorLegend = writable(false);
export let mapLayerSwitcherVisible = writable(false);
export let mapOpacitySliderVisible = writable(false);

import authkey from '../../../static/authkey/key.json';

export let mapBackgroundLayers = readable({
	none: { label: 'Kein Hintergrund' },
	luftbilder2019: {
		label: 'Luftbilder 2019',
		source: {
			type: 'raster',
			tiles: [
				'https://sg.geodatenzentrum.de/wms_dop__$SESSIONCOOKIE$?SERVICE=WMS&&REQUEST=GetMap&version=1.3.0&LAYERS=rgb&CRS=EPSG:3857&STYLES=&bbox={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
			],
			tileSize: 256,
			attribution: '&copy; GeoBasis-DE / BKG 2022 '
		},
		authentication: {
			key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
			domain: 'incora-flaeche.de'
		}
	},
	luftbilder2011_2013: [
		{
			label: 'Luftbilder 2008 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=11&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
		{
			label: 'Luftbilder 2009 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=12&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
		{
			label: 'Luftbilder 2010 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=13&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
		{
			label: 'Luftbilder 2011 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=14&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
		{
			label: 'Luftbilder 2012 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=15&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
		{
			label: 'Luftbilder 2013 (bkg)',
			source: {
				type: 'raster',
				tiles: [
					'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=15&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
				],
				tileSize: 256,
				attribution: '&copy; GeoBasis-DE / BKG 2022 '
			},
			authentication: {
				key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
				domain: 'incora-flaeche.de'
			}
		},
	],
// 	luftbilder2013: {
// 		label: 'Luftbilder 2013 (bkg)',
// 		source: {
// 			type: 'raster',
// 			tiles: [
// 				 'https://sg.geodatenzentrum.de/wms_dop_hist__$SESSIONCOOKIE$?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=16&CRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE'
// 			],
// 			tileSize: 256,
// 			attribution: '&copy; GeoBasis-DE / BKG 2022 '
// 		},
// 		authentication: {
// //			key: 'a0dffbba-105a-5e6b-1350-86431caab5c3', //netlify/fina
// //			key: '1175aede-ecac-6bd4-93c6-dfbf2cfa13d6', //incora-flaeche.de/fina
// 			key: '6db8a49c-9f1d-23a3-c600-5af92cfa13d6', //incora-flaeche.de/fleischer
// //			domain: 'incora-flaecheninfo.netlify.app',
// 			domain: 'incora-flaeche.de'
// 		}
// 	},
// 	luftbilder2015: {
// 		label: 'Luftbilder 2015 (ab Zoomstufe Gemeinde)',
// 		source: {
// 			type: 'raster',
// 			tiles: [
// 				'https://gs.incora-flaeche.de/ils-dop/wms?service=WMS&version=1.3.0&request=GetMap&layers=ils-dop:dop2015&CRS=EPSG:3857&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&TRANSPARENT=TRUE&authkey='+authkey.authkey
// 			],
// 			tileSize: 256,
// 			attribution: '&copy; GeoBasis-DE / BKG 2022 '
// 		},
// //		authentication: {
// //			key: 'a0dffbba-105a-5e6b-1350-86431caab5c3',
// //			domain: 'incora-flaecheninfo.netlify.app'
// //		}
// 	},
	osm: {
		label: 'OpenStreetMap',
		source: {
			type: 'raster',
			tiles: ['https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'],
			tileSize: 256,
			attribution:
				"&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
		}
	}
	// ,
	// osmc: {
	// 	label: 'OpenStreetMap',
	// 	source: {
	// 		type: 'raster',
	// 		tiles: ['https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'],
	// 		tileSize: 256,
	// 		attribution:
	// 			"&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
	// 	}
	// }
});

export let mapBackgroundLayersNames = derived(
	[mapBackgroundLayers],
	([$mapBackgroundLayers]) => {
		const entries = Array();
		for (const key of Object.keys($mapBackgroundLayers)) {
			if (key=="luftbilder2011_2013") { 
				entries[key]='Historische Luftbilder (2009 bis 2013)'
			}
			else {
				entries[key]=$mapBackgroundLayers[key].label
			}
		}
		return entries;
	}
);

export let mapSelectedBackgroundLayer = writable('none');
export let basemapSessionCookie = writable(false);

export const mapLayerOpacity = writable(0.6);

export const adminTypes = readable({
	bundesland: {
		label: 'Bundesländer',
		importance: 4
	},
	raumordnungsregion: {
		label: 'Raumordnungsregionen',
		importance: 3
	},
	kreis: {
		label: 'Landkreise',
		importance: 2
	},
	gemeindeverband: {
		label: 'Gemeindeverbände',
		importance: 1
	}
});

export const selectedAdminType = writable('bundesland');
export const selectedAdminId = writable(null);
export const selectedAdminName = derived(
	[selectedAdminId, lookupTable],
	([$selectedAdminId, $lookupTable]) => {
		const id = $selectedAdminId || '-1';
		return $lookupTable[id].name_gebietseinheit;
	}
);

export const selectedAdminNameArt = derived(
	[selectedAdminId, lookupTable],
	([$selectedAdminId, $lookupTable]) => {
		const id = $selectedAdminId || '-1';
		if(id === '-1'){
			return '';
		}else{
			return $lookupTable[id].art_gebietseinheit === 'Gemeindeverband' ? "("+$lookupTable[id].kategorie_gebietseinheit+")"
			: "("+$lookupTable[id].art_gebietseinheit+")";
		}
	}
);





export const dataViewTypes = readable({
	analyse: {
		label: 'Indikatoren',
		icon: 'chart-bar'
	},
	veraenderung: {
		label: 'Veränderung',
		icon: 'chart-line'
	},
	klassen: {
		label: 'Datengrundlage',
		icon: 'satellite'
	}
});
export const selectedDataView = writable('analyse');

export const selectedAdminData = writable(false);
export const indicatorData = writable(false);

// the start and end year of the data as array [start,end]
export const selectedAdminDataYears = derived([selectedAdminData], ([$selectedAdminData]) => {
	const keys = Object.keys($selectedAdminData).filter((key) => key.includes('class_'));
	if (keys.length) {
		return [keys[0].split('_')[1], keys[1].split('_')[1]];
	} else {
		return [0, 0];
	}
});

// state navigation elements
export const infoBarVisible = writable(true);
export const visibleSidebarEl = writable(1);
export const topNavVisible = writable(false);
export const mobileSearchVisible = writable(false);

// the width and height settings have to be included in the request but can be random since they are replaced by mapbox
const wmsDefaultSettings =
	'&service=WMS&request=GetMap&FORMAT=image/png&TRANSPARENT=true&bbox={bbox-epsg-3857}&width=569&height=768&SRS=EPSG:3857';

// wms layers
export const veraenderungsLayers = readable({
	'incora-bodenbedeckung': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Aincora_landcover_change_2019_20&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?&service=WMS&version=1.1.1&epsg=4326&request=GetFeatureInfo&styles=&format=image%2Fpng&transparent=true&zIndex=1&tiled=true&srs=EPSG%3A4326&info_format=application%2Fjson&layers=geonode%3Aincora_landcover_change_2019_20&query_layers=geonode%3Aincora_landcover_change_2019_20',
		type: 'wms',
		attribution: '&copy; European Space Agency (ESA)'
	},
	'incora-bodenversiegelung': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Aincora_imperviousness_change_2018_19&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?&service=WMS&version=1.1.1&epsg=4326&request=GetFeatureInfo&styles=&format=image%2Fpng&transparent=true&zIndex=1&tiled=true&srs=EPSG%3A4326&info_format=application%2Fjson&layers=geonode%3Aincora_imperviousness_change_2018_19&query_layers=geonode%3Aincora_imperviousness_change_2018_19',
		type: 'wms',
		attribution: '&copy; European Space Agency (ESA)'
	},
	'europe-clc': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode:clc_change_2012_18_wms&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?&service=WMS&version=1.1.1&epsg=4326&request=GetFeatureInfo&styles=&format=image%2Fpng&transparent=true&zIndex=1&tiled=true&srs=EPSG%3A4326&info_format=application%2Fjson&layers=geonode:clc_change_2012_18_wms&query_layers=geonode:clc_change_2012_18_wms',
		type: 'wms',
		attribution: '&copy; European Environmental Agency'
	},
	'europe-ci': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Acopernicus_imperviousness_change_2015_18&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?&service=WMS&version=1.1.1&epsg=4326&request=GetFeatureInfo&styles=&format=image%2Fpng&transparent=true&zIndex=1&tiled=true&srs=EPSG%3A4326&info_format=application%2Fjson&layers=geonode%3Acopernicus_imperviousness_change_2015_18&query_layers=geonode%3Acopernicus_imperviousness_change_2015_18',
		type: 'wms',
		attribution: '&copy; European Environmental Agency'
	},
	'destatis-regional': {type: false, attribution: '&copy; Statistisches Bundesamt'},
	'geobasisdaten-alkis': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Aalkis_change_17_20&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&TRANSPARENT=true&QUERY_LAYERS=geonode%3Aalkis_change_17_20&STYLES&LAYERS=geonode%3Aalkis_change_17_20&INFO_FORMAT=application%2Fjson&srs=EPSG%3A4326',
		type: 'wms',
		attribution: '&copy; Land NRW - Bezirksregierung Köln'
	},
	'geobasisdaten-atkis': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Aatkis_cha_18_20&version=1.1.0${wmsDefaultSettings}`,
		queryBase: 'https://ils-geomonitoring.de/geoserver/geonode/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&TRANSPARENT=true&QUERY_LAYERS=geonode:atkis_cha_18_20&STYLES&LAYERS=geonode:atkis_cha_18_20&INFO_FORMAT=application%2Fjson&srs=EPSG%3A4326',
		type: 'wms',
		attribution: '&copy; Bundesamt für Kartographie und Geodäsie 2022'
	}
});

export const klassenLayers = readable({
	'incora-bodenbedeckung': {
		url: `https://geoserver.mundialis.de/geoserver/incora/wms?layers=germany_classification_2020&version=1.1.0${wmsDefaultSettings}`,
		type: 'wms',
		attribution: '&copy; European Space Agency'
	},
	'incora-bodenversiegelung': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Aincora_impervious&version=1.1.0${wmsDefaultSettings}`,
		type: 'wms',
		attribution: '&copy; European Space Agency'
	},
	'europe-clc': {
		url: `https://sgx.geodatenzentrum.de/wms_clc5_2018?version=1.3.0&request=GetMap&LAYERS=clc5&CRS=EPSG:3857&STYLES=&bbox={bbox-epsg-3857}&WIDTH=500&HEIGHT=500&FORMAT=image/png&TRANSPARENT=TRUE&SERVICE=WMS`,
		type: 'wms',
		attribution: '&copy; European Environmental Agency'
	},
	'europe-ci': {
		url: `https://ils-geomonitoring.de/geoserver/geonode/wms?layers=geonode%3Ahrl_imperviousness&version=1.1.0${wmsDefaultSettings}`,
		type: 'wms',
		attribution: '&copy; European Environmental Agency'
	},
	'destatis-regional': {type: false, attribution: '&copy; Statistisches Bundesamt'},
	'geobasisdaten-alkis': {type: false, attribution: '&copy; Land NRW - Bezirksregierung Köln'},
	'geobasisdaten-atkis': {type: false, attribution: '&copy; Bundesamt für Kartographie und Geodäsie (BKG)'},
});

export const datasetUnits = readable({
	'incora-bodenbedeckung': ['ha','prozent'],
	'incora-bodenversiegelung': ['ha'],
	'europe-clc': ['ha','prozent'],
	'europe-ci':['ha'],
	'destatis-regional': ['ha','prozent'],
	'geobasisdaten-alkis': ['ha','prozent'],
	'geobasisdaten-atkis': ['ha','prozent']
});

// Indicator Data
export const selectedIndicator = writable('siedlung_baulich-gepraegt_ha-jahr');
export const selectedIndicatorGroup = writable('Siedlung');

export const indicatorTypesInfo = readable({
	'siedlung_baulich-gepraegt_prozent-jahr': {
		label: 'Anstieg baulich geprägte Flächen',
		unit: '% pro Jahr',
		unitBtn: '% J.',
		symbol: '%',
		decimals: 2,
		slug: 'anstieg-baulich-gepraegte-flaechen',
		altLabel:{
			'destatis-regional': 'Anstieg Siedlungs- und Verkehrsflächen',
			'geobasisdaten-alkis': 'Anstieg Siedlungs- und Verkehrsflächen'
		}
	},
	'siedlung_baulich-gepraegt_ha-jahr': {
		label: 'Anstieg baulich geprägte Flächen',
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		list:true,
		decimals: 1,
		slug: 'anstieg-baulich-gepraegte-flaechen',
		altLabel:{
			'destatis-regional': 'Anstieg Siedlungs- und Verkehrsflächen',
			'geobasisdaten-alkis': 'Anstieg Siedlungs- und Verkehrsflächen'
		}
	},
	'siedlung_baulich-gepraegt_ha-tag': {
		label: 'Anstieg baulich geprägte Flächen',
		unit: 'ha pro Tag',
		unitBtn: 'ha T.',
		symbol: 'ha',
		decimals: 3,
		slug: 'anstieg-baulich-gepraegte-flaechen'
	},
	'siedlung_bebauung-ausser-ortslagen_prozent-jahr': {
		label: 'Neue Bebauung außerhalb von Ortslagen',
		unit: '% pro Jahr',
		unitBtn: '% J.',
		symbol: '%',
		decimals: 3,
		slug: 'neue-bebauung-ausserhalb-ortslagen'
	},
	'siedlung_bebauung-ausser-ortslagen_ha-jahr': {
		label: 'Neue Bebauung außerhalb von Ortslagen',
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		list:true,
		decimals: 2,
		slug: 'neue-bebauung-ausserhalb-ortslagen'
	},
	'siedlung_veraenderung-nutzungsintensitaet_ew-ha': {
		label: 'Veränderung der Bebauungsdichte',
		unit: 'EW pro ha',
		unitBtn: 'EW ha',
		symbol: 'EW/ha',
		decimals: 0,
		list:true,
		slug: 'veraenderung-nutzungsintensitaet'
	},
	'siedlung_veraenderung-nutzungsintensitaet_qm-ew': {
		label: 'Veränderung der Bebauungsdichte',
		unit: 'qm pro EW',
		unitBtn: 'qm EW',
		symbol: 'qm/EW',
		decimals: 0,
		slug: 'veraenderung-nutzungsintensitaet'
	},
	'siedlung_ueberbauung-landwirtschaftsflaeche_prozent-jahr': {
		label: 'Überbauung von Landwirtschaftsfläche',
		unit: '% pro Jahr',
		unitBtn: '% J.',
		symbol: '%',
		decimals: 3,
		slug: 'ueberbauung-landwirtschaftsflaeche'
	},
	'siedlung_ueberbauung-landwirtschaftsflaeche_ha-jahr': {
		label: 'Überbauung von Landwirtschaftsfläche',
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		list:true,
		decimals: 2,
		slug: 'ueberbauung-landwirtschaftsflaeche'
	},
	'siedlung_bebauung-geschuetzte-gebiete_prozent-jahr': {
		label: 'Bebauung auf geschützten Gebieten',
		unit: '% pro Jahr',
		unitBtn: '% J.',
		symbol: '%',
		decimals: 3,
		slug: 'neue-bebauung-schutzgebiete'
	},
	'siedlung_bebauung-geschuetzte-gebiete_ha-jahr': {
		label: 'Bebauung auf geschützten Gebieten',
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		list:true,
		decimals: 2,
		slug: 'neue-bebauung-schutzgebiete'
	},
	'vegetation_veraenderung-gruenueberdeckung_ha-jahr': {
		label: 'Vegetationsbedeckung der Bezugsfläche', // formerly 'Grünüberdeckung'
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		decimals: 1,
		list: true,
		slug: 'veraenderung-gruenueberdeckung'
	},
	// 'vegetation_veraenderung-gruenueberdeckung_ha-jahr': {
	// 	label: 'Vegetationsbedeckung der Bezugsfläche', // formerly 'Grünüberdeckung'
	// 	unit: 'ha pro Jahr',
	// 	unitBtn: 'ha J.',
	// 	symbol: 'ha',
	// 	decimals: 1,
	// 	list: true,
	// 	slug: 'veraenderung-gruenueberdeckung'
	// },
	'vegetation_veraenderung-stadtgruen_prozent-jahr': {
		label: 'Vegetationsbedeckung innerhalb von Ortslagen', // formerly 'Stadtgrün'
		unit: '% pro Jahr',
		unitBtn: '% J.',
		symbol: '%',
		decimals: 2,
		slug: 'veraenderung-stadtgruen'
	},
	'vegetation_veraenderung-stadtgruen_ha-jahr': {
		label: 'Vegetationsbedeckung innerhalb von Ortslagen', // formerly 'Stadtgrün'
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		list:true,
		decimals: 1,
		slug: 'veraenderung-stadtgruen'
	},
	'vegetation_veraenderung-gruenueberdeckung-ausser-ortslage_ha-jahr': {
		label: 'Vegetationsbedeckung außerhalb von Ortslagen',
		unit: 'ha pro Jahr',
		unitBtn: 'ha J.',
		symbol: 'ha',
		decimals: 1,
		list:true,
		slug: 'gruenueberdeckung-siedlungsfreiraum'
	},
	'vegetation_veraenderung-gruenflaechenausstattung_qm-ew': {
		label: 'Grünflächenausstattung der Bezugsfläche',  // formerly 'Grünflächenausstattung'
		unit: 'qm pro EW',
		unitBtn: 'qm EW',
		symbol: 'qm/EW',
		decimals: 0,
		list:true,
		slug: 'veraenderung-gruenflaechenausstattung'
	}
});


export const indicatorListSiedlung = derived(
	[indicatorTypesInfo],
	([$indicatorTypesInfo]) => {
		const list = [];
		const copyIndicatorTypesInfo = JSON.parse(JSON.stringify($indicatorTypesInfo));
		for (const key in copyIndicatorTypesInfo) {
			if (key.indexOf('siedlung') !== -1 && copyIndicatorTypesInfo[key].list) {
				copyIndicatorTypesInfo[key].value = key;
				list.push(copyIndicatorTypesInfo[key]);
			}
		}
		return list;
	}
);

export const indicatorListVegetation= derived(
	[indicatorTypesInfo],
	([$indicatorTypesInfo]) => {
		const list = [];
		const copyIndicatorTypesInfo = JSON.parse(JSON.stringify($indicatorTypesInfo));
		for (const key in copyIndicatorTypesInfo) {
			if (key.indexOf('vegetation') !== -1 && copyIndicatorTypesInfo[key].list) {
				copyIndicatorTypesInfo[key].value = key;
				list.push(copyIndicatorTypesInfo[key]);
			}
		}
		return list;
	}
);



// object with all existing classes
// and their props: color, label
export const classTypes = readable({
	'incora-bodenbedeckung':{
		'baulich-gepraegte-flaeche':{
			color: '#c1272d',
			label: 'Baulich geprägt'
		},
		landwirtschaft: {
			color: '#c69c6c',
			label: 'Landwirtschaft'
		},
		'hohe-vegetation':{
			color: '#006837',
			label: 'Hohe Vegetation'
		},
		'niedrige-vegetation':{
			color: '#9ec669',
			label: 'Niedrige Vegetation'
		},
		'wasser':{
			color: '#428bbc',
			label: 'Wasser'
		},
		'vegetationslose-flaeche':{
			color: '#666666',
			label: 'Vegetationslos'
		},
	},
	'incora-bodenversiegelung':{
		"50-66":{
			color: '#e8d2fd',
			label: 'niedrig'
		},
		"67-83":{
			color: '#a39fcb',
			label: 'mittel'
		},
		"84-100":{
			color: '#3f007d',
			label: 'hoch'
		},
		"abnahme-versiegelte":{
			color: '#736357',
			label: 'Abnahme'
		},
		"zunahme-versiegelte" :{
			color: '#c1272d',
			label: 'Zunahme'
		}
	},
	'europe-clc': {
		'bebaute-flaechen_staedtisch-gepraegte-flaechen': {
			color: '#c1272d',
			label: 'Städtisch geprägt'
		},
		'bebaute-flaechen_industrieflaechen': {
			color: '#9e015d',
			label: 'Industrie'
		},
		'bebaute-flaechen_abbauflaechen-deponien-baustellen': {
			color: '#746358',
			label: 'Abbau, Deponien'
		},
		'bebaute-flaechen_kuenstlich-angelegte-flaechen': {
			color: '#ebbe2e',
			label: 'Künstlich angelegt'
		},
		landwirtschaft: {
			color: '#c69c6c',
			label: 'Landwirtschaft'
		},
		waelder: {
			color: '#006837',
			// label: 'Wälder & naturnahe Flächen'
			label: 'Wälder, naturnah'
		},
		feuchtflaechen: {
			color: '#20b4a9',
			label: 'Feuchtflächen'
		},
		wasserflaechen: {
			color: '#438bbc',
			label: 'Wasser'
		}
	},
	'europe-ci': {
		'01-20': {
			color: '#fff5f0',
			label: '1-20 %'
		},
		'20-40': {
			color: '#fdccb8',
			label: '20-40 %'
		}, 
		'40-60':{
			color: '#fc8f6f',
			label: '40-60 %'
		},   
		'60-80':{
			color: '#f44d38',
			label: '60-80 %'
		},
		'80-100':{
			color: '#c5161c',
			label: '80-100 %'
		},    
		"abnahme-versiegelte":{
			color: '#736357',
			label: 'Abnahme'
		},
		"zunahme-versiegelte" :{
			color: '#c1272d',
			label: 'Zunahme'
		}
	},
	'destatis-regional': {
		'bebaute-flaechen_staedtisch-gepraegte-flaechen': {
			color: '#c1272d',
			label: 'Baulich geprägt'
		},
		'bebaute-flaechen_industrieflaechen': {
			color: '#9e015d',
			label: 'Industrie'
		},
		'bebaute-flaechen_abbauflaechen-deponien-baustellen': {
			color: '#746358',
			label: 'Abbau, Deponien'
		},
		'bebaute-flaechen_kuenstlich-angelegte-flaechen': {
			color: '#ebbe2e',
			label: 'Siedlungsfreifläche'
		},
		landwirtschaft: {
			color: '#c69c6c',
			label: 'Landwirtschaft'
		},
		waelder: {
			color: '#006837',
			label: 'Wälder, naturnah'
		},
		feuchtflaechen: {
			color: '#20b4a9',
			label: 'Feuchtflächen'
		},
		wasserflaechen: {
			color: '#438bbc',
			label: 'Wasser'
		}
	},
	'geobasisdaten-alkis': {
		'bebaute-flaechen_staedtisch-gepraegte-flaechen': {
			color: '#c1272d',
			label: 'Baulich geprägt'
		},
		'bebaute-flaechen_industrieflaechen': {
			color: '#9e015d',
			label: 'Industrie'
		},
		'bebaute-flaechen_abbauflaechen-deponien-baustellen': {
			color: '#746358',
			label: 'Abbau, Deponien'
		},
		'bebaute-flaechen_kuenstlich-angelegte-flaechen': {
			color: '#ebbe2e',
			label: 'Siedlungsfreifläche'
		},
		landwirtschaft: {
			color: '#c69c6c',
			label: 'Landwirtschaft'
		},
		waelder: {
			color: '#006837',
			label: 'Wälder, naturnah'
		},
		feuchtflaechen: {
			color: '#20b4a9',
			label: 'Feuchtflächen'
		},
		wasserflaechen: {
			color: '#438bbc',
			label: 'Wasser'
		}
	},
	'geobasisdaten-atkis': {
		'bebaute-flaechen_staedtisch-gepraegte-flaechen': {
			color: '#c1272d',
			label: 'Baulich geprägt'
		},
		'bebaute-flaechen_industrieflaechen': {
			color: '#9e015d',
			label: 'Industrie'
		},
		'bebaute-flaechen_abbauflaechen-deponien-baustellen': {
			color: '#746358',
			label: 'Abbau, Deponien'
		},
		'bebaute-flaechen_kuenstlich-angelegte-flaechen': {
			color: '#ebbe2e',
			label: 'Siedlungsfreifläche'
		},
		landwirtschaft: {
			color: '#c69c6c',
			label: 'Landwirtschaft'
		},
		waelder: {
			color: '#006837',
			label: 'Wälder, naturnah'
		},
		feuchtflaechen: {
			color: '#20b4a9',
			label: 'Feuchtflächen'
		},
		wasserflaechen: {
			color: '#438bbc',
			label: 'Wasser'
		}
	}
});


export const reverseLookup = readable({
	'Deutschland gesamt': -1,
	'kreisfreie Großstadt': -2,
	'Städtischer Kreis': -3,
	'Ländlicher Kreis mit Verdichtungsansätzen': -4,
	'Dünn besiedelter ländlicher Kreis': -5,
	Großstadt: -6,
	Mittelstadt: -7,
	'Größere Kleinstadt': -8,
	'Kleine Kleinstadt': -9,
	Landgemeinde: -10,
	Regierungsbezirke: -11
});

export const datensatzLabelWidth = readable({
	'europe-ci': 75,
	'incora-bodenversiegelung':75
});

export let expertModeVisible = writable(false);